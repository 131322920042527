/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import './App.css';
import Cover from './pages/cover';

function App() {
  return (
    <div className="App">
      <Cover />
    </div>
  );
}

export default App;
