/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import A4Box from '../components/a4Box';

function Cover() {
  return (
    <div>
      <A4Box />
    </div>
  );
}

export default Cover;
